import { createApp, provide, h } from "vue";
import App from "./App.vue";
import { createPrismic } from "@prismicio/vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import gsap from "gsap";
import Ease from "gsap/EasePack";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(Ease, ScrollTrigger);

const prismic = createPrismic({
  endpoint: "https://hautes-cotes.prismic.io/api/v2",
  clientConfig: {
    accessToken:
      "MC5ZWlpjUmhJQUFDd0F2Y3Ex.HBMnCVTvv73vv73vv70fa--_vUXvv73vv73vv71Lau-_ve-_ve-_vTM_JRzvv73vv71lQFHvv71n77-9",
  },
  linkResolver: (doc) => {
    if (doc.type === "Journey Package") {
      return "/journey/" + doc.uid;
    }
    return "/404";
  },
});

createApp({
  setup() {
    provide("$URL", process.env.VUE_APP_URL);
  },
  render: () => h(App),
})
  .use(i18n)
  .use(store)
  .use(prismic)
  .use(router)
  .mount("#app");
