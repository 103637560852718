<template>
  <div class="intro" ref="sub">
    <video playsinline loop="true" ref="video" muted id="bgvid">
      <source :src="`${$URL}assets/video-home.webm`" type="video/webm" />
      <source :src="`${$URL}assets/video-home.mp4`" type="video/mp4" />
    </video>
    <div class="header">
      <img
        :src="`${$URL}assets/img/logo.svg`"
        alt="Logo Hautes Cotes"
        class="introImg"
      />
      <IntroLocaleSwitcher class="switch" />
    </div>
    <div class="xxl-title">
      <h1>
        <div>
          <span class="left">{{ t("intro.title.first") }}</span>
        </div>
        <div>
          <span class="right">{{ t("intro.title.second") }}</span>
        </div>
        <div>
          <span class="center">{{ t("intro.title.third") }}</span>
        </div>
      </h1>
    </div>
    <div class="bottom">
      <button class="button" @click="removeIntro()">
        <span>{{ t("intro.cta") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref, watch } from "vue";
import IntroLocaleSwitcher from "@/components/IntroLocaleSwitcher";
import { useStore } from "vuex";
import gsap from "gsap";

export default {
  name: "Intro",
  inject: ["$URL"],
  components: {
    IntroLocaleSwitcher,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const sub = ref(null);
    const video = ref(null);

    const introReady = computed(() => {
      return store.state.showIntro;
    });

    watch(introReady, () => {
      if (introReady.value === true) {
        showIntro();
        video.value.play();
      }
    });

    const showIntro = () => {
      gsap.to(sub.value.querySelectorAll("h1 div span"), {
        delay: 0,
        duration: 1.4,
        translateY: 0,
        ease: "power4.out",
        stagger: {
          from: "start",
          amount: 0.2,
        },
      });
      gsap.to(sub.value.querySelector(".bottom"), {
        delay: 0.3,
        duration: 1.4,
        translateY: 0,
        opacity: 1,
        ease: "power4.out",
      });
    };

    const removeIntro = () => {
      gsap.to(sub.value, {
        delay: 0,
        duration: 1.23,
        translateY: "-100%",
        ease: "power4.out",
        onComplete: () => {
          sub.value.parentNode.remove(sub.value);
        },
      });
    };

    onMounted(() => {
      if (introReady.value === true) {
        showIntro();
      }
    });

    return {
      t,
      showIntro,
      sub,
      removeIntro,
      video,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
:root {
  --vh: 100vh;
}

video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background: url("/assets/img/intro_background.webp") no-repeat;
  background-size: cover;
  background-position: center center;
}

.intro {
  position: absolute;
  overflow: hidden;
  z-index: 999;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @include respond-to("md-down") {
    justify-content: space-between;
    padding: 15px 20px;
  }
}
.header {
  display: flex;
  align-items: center;
  & .switch {
    position: absolute;
    right: 4%;
  }
}

h1 {
  color: #fff;
  & div {
    overflow: hidden;
    & span {
      display: block;
      transform: translateY(100%);
      &.right {
        text-align: right;
      }
    }
  }
}

.bottom {
  opacity: 0;
  position: relative;
  bottom: 2.5rem;
}
.button {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 40px;
  margin: 0;
  color: #fff;
  font-size: 14px;
  transition: background-color 0.21s ease-out;
  letter-spacing: 0.06em;
  cursor: pointer;
  height: auto;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
</style>
