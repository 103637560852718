<template>
  <div class="loadScreen" ref="progressLoader">
    <div class="logo">
      <div class="contain">
        <svg
          version="1.1"
          id="Calque_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 350 350"
          style="enable-background: new 0 0 350 350"
          xml:space="preserve"
          ref="svg"
        >
          <line
            class="st0 strokeOne"
            x1="77.4"
            y1="294.3"
            x2="77.4"
            y2="54.7"
          />
          <line
            class="st0 strokeFour"
            x1="207.5"
            y1="54.7"
            x2="207.5"
            y2="294.3"
          />
          <line
            class="st0 strokeTwo"
            x1="77.4"
            y1="174.7"
            x2="207.5"
            y2="174.7"
          />
          <path
            class="st0 strokeThree"
            d="M272.7,235.2c-12.1,35.6-34.7,59.5-60.5,59.5c-38.7,0-70-53.7-70-120s31.3-120,70-120 c25.8,0,48.3,23.9,60.5,59.5"
          />
          <rect
            x="48.4"
            y="25"
            class="st0 square"
            width="253.3"
            height="299.5"
          />
          <circle class="st1 dot" cx="273" cy="291.1" r="5" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, watch, onMounted } from "vue";
import gsap from "gsap";

export default {
  name: "ProgressLoader",
  setup() {
    const store = useStore();
    const indicator = ref(null);
    const progressLoader = ref(null);
    const svg = ref(null);
    const end = ref(false);
    const tl = gsap.timeline();

    const progressPercentage = computed(() => {
      return store.state.assetsLoadingProgress;
    });
    const progressFinished = computed(() => {
      return store.state.assetsLoaded;
    });

    onMounted(() => {
      tl.to(svg.value.querySelector(".strokeOne"), {
        strokeDashoffset: 0,
        duration: 0.8,
      })
        .to(
          svg.value.querySelector(".strokeTwo"),
          {
            strokeDashoffset: 0,
            duration: 0.8,
          },
          "-=50%"
        )
        .to(
          svg.value.querySelector(".strokeThree"),
          {
            strokeDasharray: "484 0",
            strokeDashoffset: 0,
            duration: 1.4,
          },
          "-=40%"
        )
        .to(
          svg.value.querySelector(".strokeFour"),
          {
            strokeDasharray: 240,
            strokeDashoffset: 0,
            duration: 0.9,
          },
          "-=110%"
        )
        .to(
          svg.value.querySelector(".dot"),
          {
            opacity: 1,
            duration: 0.8,
          },
          "-=10%"
        )
        .to(
          svg.value.querySelector(".square"),
          {
            opacity: 1,
            duration: 1.2,
            onComplete: () => {
              end.value = true;
            },
          },
          "-=40%"
        );
    });

    watch(progressPercentage, () => {
      tl.tweenFromTo(
        tl.progress() * tl.totalDuration(),
        (progressPercentage.value / 100) * tl.totalDuration()
      );
    });
    watch(end, () => {
      if (end.value === true) {
        progressFinishedAnimation();
      }
    });

    const progressFinishedAnimation = () => {
      const timeline = new gsap.timeline({
        onComplete() {
          store.state.showIntro = true;
          gsap.set(progressLoader.value, {
            css: {
              "pointer-events": "none",
            },
          });
        },
      });
      timeline
        .to(progressLoader.value.querySelector(".logo"), 0.8, {
          css: {
            opacity: 0,
          },
          ease: "power4.out",
        })
        .to(progressLoader.value, 1, {
          css: {
            opacity: 0,
          },
          ease: "power4.out",
        });
    };

    return {
      store,
      indicator,
      progressLoader,
      progressPercentage,
      progressFinished,
      svg,
    };
  },
};
</script>

<style lang="scss" scoped>
.loadScreen {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f5ebe6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.55s opacity ease-out;
  opacity: 1;
  z-index: 2000;
}

.logo {
  height: 10rem;
  width: 10rem;
  overflow-y: hidden;
}

.contain {
  background-image: url("/assets/img/logo-square.svg");
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 20rem;
}

.complete {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
}

.strokeOne {
  stroke: #000;
  stroke-dasharray: 240 240;
  stroke-dashoffset: -240;
}

.strokeTwo {
  stroke: #000;
  stroke-dasharray: 131 131;
  stroke-dashoffset: 131;
}

.strokeThree {
  stroke: #000;
  stroke-dasharray: 0 480;
  stroke-dashoffset: -240;
}

.strokeFour {
  stroke: #000;
  stroke-dasharray: 0 240;
  stroke-dashoffset: -122;
}

.dot {
  fill: #000;
  opacity: 0.2;
}

.square {
  stroke: #000;
  opacity: 0.2;
}
.st0 {
  fill: none;
  stroke-width: 7;
}
</style>
