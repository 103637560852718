<template>
  <div class="navbar" ref="menu">
    <div class="header" :class="[{ blend: $route.name != 'Home' || menuOpen }]">
      <router-link
        :to="{
          name: 'Home',
          params: { locale: `${$route.params.locale}`, trans: false },
        }"
        @click="menuOpen && toggleMenu()"
      >
        <transition name="fade" mode="out-in">
          <div :key="hb_logo" class="logonav" :class="[{ visible: !visible }]">
            <svg
              width="188"
              height="40"
              viewBox="0 0 400 86.49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="hb_logo || menuOpen"
            >
              <g id="Calque_2" data-name="Calque 2">
                <g id="Calque_1-2" data-name="Calque 1">
                  <path
                    class="cls-1"
                    d="M36.46,13.26a5.62,5.62,0,0,0-2.72.84,3.54,3.54,0,0,0-1.16,2.25,29.64,29.64,0,0,0-.31,5.09V76.33a29.43,29.43,0,0,0,.31,5.08,3.55,3.55,0,0,0,1.16,2.26,5.62,5.62,0,0,0,2.72.84v1h-14v-1a5.62,5.62,0,0,0,2.72-.84,3.55,3.55,0,0,0,1.16-2.26,29.43,29.43,0,0,0,.31-5.08V49.62H9.85V76.33a29.43,29.43,0,0,0,.31,5.08,3.55,3.55,0,0,0,1.16,2.26,5.62,5.62,0,0,0,2.72.84v1H0v-1a5.62,5.62,0,0,0,2.72-.84,3.55,3.55,0,0,0,1.16-2.26,29.43,29.43,0,0,0,.31-5.08V21.44a29.64,29.64,0,0,0-.31-5.09A3.54,3.54,0,0,0,2.72,14.1,5.62,5.62,0,0,0,0,13.26v-1H14v1a5.62,5.62,0,0,0-2.72.84,3.54,3.54,0,0,0-1.16,2.25,29.64,29.64,0,0,0-.31,5.09V47.52H26.61V21.44a29.64,29.64,0,0,0-.31-5.09,3.54,3.54,0,0,0-1.16-2.25,5.62,5.62,0,0,0-2.72-.84v-1h14Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M69.67,76.44a25.94,25.94,0,0,0,1.16,5.08,4.05,4.05,0,0,0,1.62,2.2,7.57,7.57,0,0,0,2.88.79v1H59.09v-1a9.23,9.23,0,0,0,4.14-1,3.18,3.18,0,0,0,1.1-2.83,30.31,30.31,0,0,0-.53-4.29L62,65.75H47.46L45.68,76.44a31.4,31.4,0,0,0-.52,4.29,3.15,3.15,0,0,0,1.1,2.83,9.21,9.21,0,0,0,4.13,1v1H37.82v-1a7.57,7.57,0,0,0,2.88-.79,4.11,4.11,0,0,0,1.63-2.2,30.25,30.25,0,0,0,1.26-5.08l10.16-60-.42-2.62v-1a11.79,11.79,0,0,0,2.72-.26,2.4,2.4,0,0,0,1.57-1.21h1.05ZM54.8,22.69l-6.92,41H61.71Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M77.11,21.44a29.64,29.64,0,0,0-.31-5.09,3.44,3.44,0,0,0-1.21-2.25,6,6,0,0,0-2.77-.84v-1H87v1a5.7,5.7,0,0,0-2.73.84,3.48,3.48,0,0,0-1.15,2.2,29.45,29.45,0,0,0-.31,5.14v44q0,9.11,2.41,13.09a8.16,8.16,0,0,0,7.54,4q5.23,0,7.7-3.87t2.46-12.16v-45a29.64,29.64,0,0,0-.31-5.09,3.59,3.59,0,0,0-1.15-2.25,5.7,5.7,0,0,0-2.73-.84v-1h10.48v1a5.62,5.62,0,0,0-2.72.84,3.54,3.54,0,0,0-1.16,2.25,29.64,29.64,0,0,0-.31,5.09V67q0,9.54-3.14,14.51t-10.06,5q-14.67,0-14.67-20.42Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M144.58,35.89h-2.09q-1.16-10.68-1.89-14.82t-1.83-5.45a4.5,4.5,0,0,0-3.62-1.31h-4.29v62a29.43,29.43,0,0,0,.31,5.08,3.54,3.54,0,0,0,1.15,2.26,5.7,5.7,0,0,0,2.73.84v1H121v-1a5.66,5.66,0,0,0,2.72-.84,3.55,3.55,0,0,0,1.16-2.26,29.43,29.43,0,0,0,.31-5.08v-62h-4.3a4.65,4.65,0,0,0-3.66,1.31q-1.15,1.3-1.84,5.4t-1.83,14.87h-2.09l1.88-23.68H142.7Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M177.06,61.87l-2,23.68H147.83v-1a5.66,5.66,0,0,0,2.72-.84,3.59,3.59,0,0,0,1.16-2.26,30.49,30.49,0,0,0,.31-5.08V21.44a30.72,30.72,0,0,0-.31-5.09,3.59,3.59,0,0,0-1.16-2.25,5.66,5.66,0,0,0-2.72-.84v-1h26.5l1.89,23.68h-2.09q-1.15-11-1.89-15.14c-.49-2.75-1.1-4.52-1.83-5.29a5.22,5.22,0,0,0-3.83-1.15h-8.9V45.64h5.65a3.28,3.28,0,0,0,2.52-.74c.42-.49.63-1.57.63-3.25s-.11-3.77-.32-6.7h2.1l2,24.52h-2.2l-.32-2.62c-.35-3.15-.62-5.26-.83-6.34a3.3,3.3,0,0,0-1.16-2.2,5.2,5.2,0,0,0-2.82-.58h-5.24V83.46h9.64a5.23,5.23,0,0,0,3.77-1.15c.76-.77,1.43-2.59,2-5.45s1.19-7.86,1.88-15Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M184.86,14.1c2.06-1.89,4.88-2.83,8.44-2.83a20.52,20.52,0,0,1,4.55.47l2.47.58a20.09,20.09,0,0,0,4.5.84l2,22.73h-2.09a89,89,0,0,0-2.31-14Q201,17,199.11,15.2a7,7,0,0,0-5-1.83,5.86,5.86,0,0,0-4.77,2.09,8.32,8.32,0,0,0-1.73,5.45,23.58,23.58,0,0,0,2.25,9.33,105.57,105.57,0,0,0,6.86,12.57q11.85,17.07,11.84,30.38,0,7.34-3.14,10.32t-9,3a23,23,0,0,1-4.09-.36c-1.32-.25-2.55-.51-3.66-.79q-.63-.11-2.1-.42a13,13,0,0,0-2.72-.31L182,61.87h2.1A66.6,66.6,0,0,0,186.7,75.7c1.11,3.29,2.41,5.56,3.87,6.81a7.94,7.94,0,0,0,5.35,1.89q6.81,0,6.81-9.53a34.9,34.9,0,0,0-2.62-13.1,90.11,90.11,0,0,0-8-14.77,135.88,135.88,0,0,1-7.85-14,25.75,25.75,0,0,1-2.52-10.9Q181.77,16.93,184.86,14.1Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M233.47,19.65q4.67-8.37,12.42-8.38a18.09,18.09,0,0,1,5.66,1.05,11.38,11.38,0,0,0,3.66.84l2,22.73h-2.09a101.48,101.48,0,0,0-2.2-14.19q-1.26-4.87-2.83-6.6a5,5,0,0,0-3.88-1.73q-4.92,0-7.86,8.17t-2.93,25.25q0,16.88,3.09,26.4t8.23,9.53a6.11,6.11,0,0,0,4.82-2.35c1.32-1.58,2.46-4.26,3.4-8.07a113.3,113.3,0,0,0,2.36-15.77l2.09.21A110.62,110.62,0,0,1,256.58,75q-1.78,6.5-4.41,9a9,9,0,0,1-6.49,2.51q-7.86,0-12.36-10.31t-4.51-29.39Q228.81,28,233.47,19.65Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M264.54,48.88q0-37.61,16.76-37.61,8.07,0,12.41,9t4.35,28.65q0,19.8-4.29,28.71t-12.47,8.9Q264.53,86.49,264.54,48.88ZM289,76.07q2.56-8.32,2.57-27.19T289,21.7q-2.56-8.34-7.7-8.33t-7.65,8.33q-2.52,8.32-2.51,27.18t2.51,27.24q2.52,8.28,7.65,8.28T289,76.07Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M336,35.89h-2.1Q332.74,25.21,332,21.07t-1.84-5.45a4.47,4.47,0,0,0-3.61-1.31h-4.3v62a29.19,29.19,0,0,0,.32,5.08,3.54,3.54,0,0,0,1.15,2.26,5.7,5.7,0,0,0,2.73.84v1h-14v-1a5.69,5.69,0,0,0,2.72-.84,3.54,3.54,0,0,0,1.15-2.26,29.19,29.19,0,0,0,.32-5.08v-62h-4.3a4.62,4.62,0,0,0-3.66,1.31q-1.15,1.3-1.84,5.4T305,35.89h-2.1l1.89-23.68H334.1Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M368.47,61.87l-2,23.68H339.24v-1a5.69,5.69,0,0,0,2.72-.84,3.54,3.54,0,0,0,1.15-2.26,29.19,29.19,0,0,0,.32-5.08V21.44a29.41,29.41,0,0,0-.32-5.09A3.54,3.54,0,0,0,342,14.1a5.69,5.69,0,0,0-2.72-.84v-1h26.5l1.89,23.68h-2.1q-1.15-11-1.88-15.14c-.49-2.75-1.1-4.52-1.84-5.29A5.19,5.19,0,0,0,358,14.31h-8.91V45.64h5.66a3.28,3.28,0,0,0,2.52-.74c.42-.49.62-1.57.62-3.25s-.1-3.77-.31-6.7h2.1l2,24.52h-2.2l-.32-2.62q-.52-4.73-.84-6.34a3.3,3.3,0,0,0-1.15-2.2,5.22,5.22,0,0,0-2.83-.58h-5.24V83.46h9.64a5.21,5.21,0,0,0,3.77-1.15c.77-.77,1.43-2.59,2-5.45s1.18-7.86,1.88-15Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M376.27,14.1q3.09-2.83,8.43-2.83a20.55,20.55,0,0,1,4.56.47l2.46.58a20.25,20.25,0,0,0,4.51.84l2,22.73h-2.1a89.17,89.17,0,0,0-2.3-14q-1.36-4.87-3.3-6.71a7,7,0,0,0-5-1.83,5.85,5.85,0,0,0-4.76,2.09,8.26,8.26,0,0,0-1.73,5.45,23.58,23.58,0,0,0,2.25,9.33,105.57,105.57,0,0,0,6.86,12.57Q400,59.88,400,73.19q0,7.34-3.14,10.32t-9,3a23,23,0,0,1-4.09-.36c-1.33-.25-2.55-.51-3.67-.79-.41-.07-1.11-.21-2.09-.42a13.06,13.06,0,0,0-2.73-.31l-1.88-22.74h2.1A66.7,66.7,0,0,0,378.1,75.7q1.68,4.93,3.88,6.81a7.93,7.93,0,0,0,5.34,1.89q6.81,0,6.81-9.53a34.71,34.71,0,0,0-2.62-13.1,90,90,0,0,0-8-14.77,138.23,138.23,0,0,1-7.86-14,25.88,25.88,0,0,1-2.51-10.9Q373.18,16.93,376.27,14.1Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                  <path
                    class="cls-1"
                    d="M273.25,5.07c1.41-3.14,5-5.07,8.59-5.07s7.19,1.9,8.61,5A20.45,20.45,0,0,0,281.84,3,20.06,20.06,0,0,0,273.25,5.07Z"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                      { menu: menuOpen },
                    ]"
                  />
                </g>
              </g>
            </svg>
            <svg
              width="52"
              height="60"
              viewBox="0 0 52 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="!hb_logo && !menuOpen"
            >
              <g clip-path="url(#clip0_35:482)">
                <path
                  d="M8.77191 30.632H19.2297C19.3222 36.3049 20.6537 41.6259 23.0109 45.6685C25.5203 49.9703 28.8859 52.3403 32.4978 52.3403C37.2531 52.3403 41.668 48.0495 44.0205 41.1422L42.8239 40.7338C40.6517 47.1119 36.6947 51.0744 32.4978 51.0744H32.2704V8.92848H32.4978C36.6947 8.92848 40.6533 12.891 42.8239 19.269L44.0205 18.8607C41.668 11.9502 37.2531 7.6626 32.4978 7.6626C28.8907 7.6626 25.5171 10.0326 23.0109 14.3343C20.6584 18.3801 19.3222 23.6996 19.2297 29.3709H8.77191V8.29082H7.50781V51.6272H8.77191V30.632ZM20.4906 30.632H30.9985V50.911C25.2003 49.6436 20.6678 41.0809 20.4906 30.632ZM30.9985 9.0871V29.3661H20.4906C20.6678 18.9172 25.2003 10.3545 31.0048 9.0871H30.9985Z"
                  :class="[
                    {
                      light:
                        (light &&
                          $route.name != 'Contact' &&
                          $route.name != 'Legals') ||
                        $route.name == 'Home',
                    },
                  ]"
                />
                <path
                  d="M0 0V59.9953H51.5625V0H0ZM50.2984 58.7388H1.2641V1.27215H50.2984V58.7388Z"
                  :class="[
                    {
                      light:
                        (light &&
                          $route.name != 'Contact' &&
                          $route.name != 'Legals') ||
                        $route.name == 'Home',
                    },
                  ]"
                />
                <path
                  d="M44.0198 52.1392C44.6963 52.1392 45.2447 51.5901 45.2447 50.9126C45.2447 50.2352 44.6963 49.686 44.0198 49.686C43.3433 49.686 42.7949 50.2352 42.7949 50.9126C42.7949 51.5901 43.3433 52.1392 44.0198 52.1392Z"
                  :class="[
                    {
                      light:
                        (light &&
                          $route.name != 'Contact' &&
                          $route.name != 'Legals') ||
                        $route.name == 'Home',
                    },
                  ]"
                />
              </g>
              <defs>
                <clipPath id="clip0_35:482">
                  <rect
                    width="51.5625"
                    height="60"
                    :class="[
                      {
                        light:
                          (light &&
                            $route.name != 'Contact' &&
                            $route.name != 'Legals') ||
                          $route.name == 'Home',
                      },
                    ]"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </transition>
      </router-link>
      <div class="button" @click="toggleMenu()">
        <svg
          width="40"
          height="12"
          viewBox="0 0 40 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref="menuButton"
          v-if="!menuOpen"
        >
          <rect
            width="40"
            height="2"
            :class="[
              {
                light:
                  (light &&
                    $route.name != 'Contact' &&
                    $route.name != 'Legals') ||
                  $route.name == 'Home',
              },
            ]"
          />
          <rect
            y="10"
            width="40"
            height="2"
            :class="[
              {
                light:
                  (light &&
                    $route.name != 'Contact' &&
                    $route.name != 'Legals') ||
                  $route.name == 'Home',
              },
            ]"
          />
        </svg>
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="menuOpen"
        >
          <rect
            x="1.56445"
            y="0.15097"
            width="40"
            height="2"
            transform="rotate(45 1.56445 0.15097)"
            fill="black"
          />
          <rect
            y="28.2843"
            width="40"
            height="2"
            transform="rotate(-45 0 28.2843)"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <transition name="fade">
      <div class="menu" v-show="menuOpen">
        <div class="images">
          <transition name="slide-fadey" mode="out-in">
            <div
              v-if="hover"
              :key="hover"
              :style="`left: ${data[hover][0].x}; bottom: ${data[hover][0].y};`"
            >
              <img
                :src="`${$URL}assets/img/menu/wine_0.webp`"
                alt=""
                v-show="hover == 'wine'"
              />
              <img
                :src="`${$URL}assets/img/menu/art_0.webp`"
                alt=""
                v-show="hover == 'art'"
              />
              <img
                :src="`${$URL}assets/img/menu/contact_0.webp`"
                alt=""
                v-show="hover == 'contact'"
              />
              <img
                :src="`${$URL}assets/img/menu/experiences_0.webp`"
                alt=""
                v-show="hover == 'experiences'"
              />
            </div>
          </transition>
          <transition name="slide-fadey" mode="out-in">
            <div
              v-if="hover"
              :key="hover"
              :style="`left: ${data[hover][1].x}; bottom: ${data[hover][1].y};`"
            >
              <img
                :src="`${$URL}assets/img/menu/wine_1.webp`"
                alt=""
                v-show="hover == 'wine'"
              />
              <img
                :src="`${$URL}assets/img/menu/art_1.webp`"
                alt=""
                v-show="hover == 'art'"
              />
              <img
                :src="`${$URL}assets/img/menu/contact_1.webp`"
                alt=""
                v-show="hover == 'contact'"
              />
              <img
                :src="`${$URL}assets/img/menu/experiences_1.webp`"
                alt=""
                v-show="hover == 'experiences'"
              />
            </div>
          </transition>
          <transition name="slide-fadey" mode="out-in">
            <div
              v-if="hover"
              :key="hover"
              :style="`left: ${data[hover][2].x}; bottom: ${data[hover][2].y};`"
            >
              <img
                :src="`${$URL}assets/img/menu/wine_2.webp`"
                alt=""
                v-show="hover == 'wine'"
              />
              <img
                :src="`${$URL}assets/img/menu/art_2.webp`"
                alt=""
                v-show="hover == 'art'"
              />
              <img
                :src="`${$URL}assets/img/menu/contact_2.webp`"
                alt=""
                v-show="hover == 'contact'"
              />
              <img
                :src="`${$URL}assets/img/menu/experiences_2.webp`"
                alt=""
                v-show="hover == 'experiences'"
              />
            </div>
          </transition>
        </div>
        <nav>
          <ul>
            <li>
              <router-link
                :to="{
                  name: 'Art',
                  params: { locale: `${$route.params.locale}`, trans: false },
                }"
                @mouseenter="handleHover('art', 0)"
                @mouseleave="handleHover('', 0)"
                @click="toggleMenu()"
                class="xl-title l_0"
                >{{ t("menu.art") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Wine',
                  params: { locale: `${$route.params.locale}`, trans: false },
                }"
                @mouseenter="handleHover('wine', 1)"
                @mouseleave="handleHover('', 1)"
                @click="toggleMenu()"
                class="xl-title l_1"
                >{{ t("menu.wine") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Experiences',
                  params: { locale: `${$route.params.locale}`, trans: false },
                }"
                @mouseenter="handleHover('experiences', 3)"
                @mouseleave="handleHover('', 3)"
                @click="toggleMenu()"
                class="xl-title l_3"
                >{{ t("menu.experiences") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Contact',
                  params: { locale: `${$route.params.locale}`, trans: false },
                }"
                @mouseenter="handleHover('contact', 2)"
                @mouseleave="handleHover('', 2)"
                @click="toggleMenu(true)"
                class="xl-title l_2"
                >{{ t("menu.contact") }}</router-link
              >
            </li>
          </ul>
          <div class="links">
            <div class="links-top">
              <router-link
                :to="{
                  name: 'We Support',
                  params: { locale: `${$route.params.locale}`, trans: false },
                }"
                @click="toggleMenu()"
                >{{ t("menu.we_support") }}</router-link
              >
            </div>
            <div class="links-top">
              <router-link
                :to="{
                  name: 'Newsletter',
                  params: { locale: `${$route.params.locale}`, trans: false },
                }"
                @click="toggleMenu()"
                >{{ t("menu.newsletter") }}</router-link
              >
            </div>
            <div class="links-bottom">
              <router-link
                :to="{
                  name: 'Legals',
                  params: { locale: `${$route.params.locale}`, trans: false },
                }"
                @click="toggleMenu()"
                >{{ t("menu.legals") }}</router-link
              >
              <span
                >Website by
                <a href="https://detailsofwar.com" target="_blank"
                  >Details of War</a
                ></span
              >
            </div>
          </div>
        </nav>
      </div>
    </transition>
  </div>
</template>

<script>
import { nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import ScrollTrigger from "gsap/ScrollTrigger";

export default {
  name: "Navbar",
  inject: ["$URL"],
  setup() {
    const data = {
      wine: {
        0: { x: "-1%", y: "45%" },
        1: { x: "13%", y: "-5%" },
        2: { x: "27%", y: "80%" },
      },
      art: {
        0: { x: "-1%", y: "32%" },
        1: { x: "18.5%", y: "80%" },
        2: { x: "27%", y: "-5%" },
      },
      contact: {
        0: { x: "-1%", y: "32%" },
        1: { x: "27%", y: "80%" },
        2: { x: "27%", y: "-5%" },
      },
      experiences: {
        0: { x: "-1%", y: "45%" },
        1: { x: "13%", y: "-5%" },
        2: { x: "27%", y: "75%" },
      },
    };
    // REF
    const menuOpen = ref(false);
    const hb_logo = ref(true);
    const menu = ref(null);
    const light = ref(true);
    const oldValue = ref(true);
    const visible = ref(true);
    const darklist = ref([]);
    const hover = ref("");
    const progress = ref(1);
    // I18N
    const locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
    const { t, locale } = useI18n({ useScope: "global" });
    // ROUTER
    const router = useRouter();
    const route = useRoute();

    const switchLocale = () => {
      if (locale.value == "fr") {
        locale.value = "en";
      } else {
        locale.value = "fr";
      }
      const to = router.resolve({
        params: { locale: locale.value },
        name: route.name,
      });
      router.push(to);
    };

    const toggleMenu = (a) => {
      menuOpen.value = !menuOpen.value;
      if (menuOpen.value) {
        document.documentElement.classList.add("overflow");
        oldValue.value = light.value;
        light.value = false;
      } else {
        document.documentElement.classList.remove("overflow");
        light.value = oldValue.value;
      }
      if (a) {
        light.value = false;
      }
      handleHover("", null);
      menu.value.querySelectorAll(".xl-title.hide").forEach((element) => {
        element.classList.remove("hide");
      });
    };

    const handleHover = (el, i) => {
      const links = menu.value.querySelectorAll(".xl-title");
      hover.value = el;

      if (i != null) {
        links.forEach((link) => {
          if (!link.classList.contains(`l_${i}`)) {
            link.classList.toggle("hide");
          }
        });
      }
    };

    watch(route, () => {
      nextTick(() => {
        ScrollTrigger.refresh();
        if (
          router.currentRoute.value.name != "Home" &&
          router.currentRoute.value.name != "Art" &&
          router.currentRoute.value.name != "Experiences" &&
          router.currentRoute.value.name != "Wine"
        ) {
          light.value = false;
        } else {
          light.value = true;
        }
      });
    });

    onMounted(() => {
      ScrollTrigger.create({
        start: 0,
        end: "max",
        onUpdate(self) {
          if (darklist.value != document.querySelectorAll(".dark")) {
            darklist.value = document.querySelectorAll(".dark");
            handleColor();
          }
          if (window.innerWidth < 835) {
            progress.value = 100;
          } else {
            progress.value = 15;
          }
          if (self.progress >= progress.value / self.end) {
            hb_logo.value = false;
            if (window.innerWidth < 835) {
              visible.value = false;
            }
          } else {
            hb_logo.value = true;
            if (window.innerWidth < 835) {
              visible.value = true;
            }
          }
        },
      });
    });
    const handleColor = () => {
      if (darklist.value.length == 0) {
        light.value = false;
      }
      darklist.value.forEach((element) => {
        // light.value = false
        ScrollTrigger.create({
          start: 0,
          end: "max",
          onUpdate() {
            if (ScrollTrigger.isInViewport(element)) {
              if (
                ScrollTrigger.positionInViewport(element, "top") <= 0.1 &&
                ScrollTrigger.positionInViewport(element, "bottom") >= 0.1
              ) {
                light.value = true;
              } else {
                light.value = false;
              }
            }
          },
        });
      });
    };
    // function handleColor() {
    //   console.log('color');
    //   document.querySelectorAll('.dark').forEach(() => {
    //     if(ScrollTrigger.positionInViewport(document.querySelectorAll('.dark'), "top")) {
    //       light.value = true
    //     } else {
    //       light.value = false
    //     }
    //   })
    // }

    return {
      t,
      locale,
      locales,
      light,
      menuOpen,
      menu,
      data,
      hover,
      switchLocale,
      toggleMenu,
      handleHover,
      hb_logo,
      visible,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
rect,
path {
  fill: #000000;
  transition: 0.4s fill linear;
  &.light {
    fill: #ffffff;
  }
  &.menu {
    fill: #473d38;
  }
}
.visible {
  opacity: 0;
}
.logonav {
  transition: 0.5s opacity ease-out;
  height: 36px;
  & svg {
    height: 36px;
  }
  @include respond-to("md-down") {
    height: 30px;
    & svg {
      height: 30px;
    }
  }
}
.hidden {
  display: none;
}
.header {
  position: fixed;
  width: 100%;
  padding: 35px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  & a {
    position: relative;
    display: inline-block;
  }
  @include respond-to("md-down") {
    padding: 15px 20px;
  }
}
.button {
  cursor: pointer;
}

.menu {
  z-index: 99;
  position: fixed;
  background: #f5ebe6;
  color: #000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.images {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  z-index: 999;
  & div {
    height: 300px;
    will-change: height;
    position: fixed;
    width: 100%;
    overflow: hidden;
    & img {
      position: absolute;
      bottom: 0;
      height: 310px;
      transform: translateY(20px);
      transition: transform 1.3s cubic-bezier(0.6, 0, 0.2, 1);
      // position: absolute;
    }
  }
  @include respond-to("md-down") {
    display: none;
  }
}
nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  @include respond-to("md-down") {
    width: 100%;
    padding: 0 10%;
  }
}
.links {
  margin-top: 80px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  @include respond-to("md-down") {
    bottom: 5%;
    left: 0;
    padding: 0 10%;
    position: fixed;
    width: 100%;
  }
  .links-top {
    margin-bottom: 10px;
  }
  .links-bottom {
    @include respond-to("md-down") {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
    }
    & > *:first-child {
      margin: 0 25px 0 0;
      @include respond-to("md-down") {
        margin: 0;
      }
    }
    & > *:not(:first-child) {
      margin: 0 25px;
      @include respond-to("md-down") {
        margin: 0;
      }
    }
    & > *:last-child {
      margin: 0 0 0 25px;
      text-transform: none;
      opacity: 0.3;
      cursor: default;
      & a {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
      @include respond-to("md-down") {
        // margin-top: 20px !important;
        margin-left: 0;
      }
    }
  }
}
ul li {
  margin: 2% 0;
  & a {
    font-family: "IvyJournal";
    font-size: 6rem;
    text-transform: uppercase;
    transition: opacity 0.24s linear;
    &.hide {
      opacity: 0.3;
    }
    @include respond-to("md-down") {
      font-size: 4rem;
    }
  }
}

span {
  cursor: pointer;
}
</style>
