<template>
  <div class="localeSwitch">
    <!-- <span @click="switchLocale()" class="text">{{ t("locale") }}</span> -->
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "IntroLocaleSwitcher",
  setup() {
    const locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
    const { t, locale } = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();

    const switchLocale = () => {
      if (locale.value == "fr") {
        locale.value = "en";
      } else {
        locale.value = "fr";
      }
      const to = router.resolve({
        params: { locale: locale.value },
        name: route.name,
      });
      router.push(to);
    };

    return {
      t,
      locale,
      locales,
      switchLocale,
    };
  },
};
</script>

<style lang="scss" scoped>
span {
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
}
</style>
