import { createStore } from "vuex";
import {
  LOAD_ASSETS,
  LOAD_ASSETS_PROGRESS,
  LOAD_ASSETS_LOADED,
  LOAD_WEBSITE,
} from "./types";
import ProgressLoaderService from "@/services/ProgressLoaderService";
import content from "./content.json";

export default createStore({
  state: {
    content,
    assetsLoadingProgress: 0,
    assetsLoaded: false,
    websiteReady: false,
    showIntro: false,
  },
  mutations: {
    [LOAD_ASSETS_PROGRESS](state, progress) {
      state.assetsLoadingProgress = progress;
    },
    [LOAD_ASSETS_LOADED](state) {
      state.assetsLoaded = true;
    },
    [LOAD_WEBSITE](state) {
      state.websiteReady = true;
    },
  },
  actions: {
    [LOAD_ASSETS]: ({ commit, state }) => {
      const progressLoaderService = new ProgressLoaderService(
        state.content.assets
      );
      progressLoaderService.on("progress", (progress) => {
        commit(LOAD_ASSETS_PROGRESS, progress);
      });
      progressLoaderService.on("complete", () => {
        commit(LOAD_ASSETS_LOADED);
        setTimeout(() => {
          commit(LOAD_WEBSITE);
        }, 4500);
      });
    },
  },
  modules: {},
});
