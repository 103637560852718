export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
  "menu": {
    "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine"])},
    "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiences"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "we_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Support"])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legals"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])}
  },
  "intro": {
    "title": {
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we build"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["art & wine"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["experiences"])}
    },
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])}
  },
  "home": {
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])},
    "art": {
      "title": {
        "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telling"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["art"])},
        "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stories"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cultural experiences"])}
    },
    "wine": {
      "title": {
        "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telling"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wine"])},
        "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stories"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wine experiences"])}
    }
  },
  "art": {
    "top": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We tell stories of place through deep dives into cultural landscapes"])},
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll to explore"])}
    },
    "enhance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crafted Cultural Experiences"])},
      "text": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes Côtes is a boutique experience planning and cultural production agency based in France. Our bespoke programs are created for collectors, patrons, and culture-seekers."])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together with curators, gallerists, art advisors, cultural foundations and diverse voices from the creative sector, we craft highly personalized experiences for varied groups. Our experiences can be tailored to accompany an exhibition, art fair, acquisition, or simply to discover a place through profound cultural immersion and surprising visits."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe that now, more than ever, travel must be meaningful. When we embark on a journey, we must do so with purpose. Culture – art, design, architecture, fashion, gastronomy and wine – has the power to touch us and others in profound ways. Our cultural programs are created to be impactful learning experiences, changing the way we see the world and understand a place — even in places we think we know well."])}
      }
    },
    "sense": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense\nof place"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission is to bring together the places, people, and ideas that ignite conversations and drive culture forward, by looking at layered histories underlying our contemporary world."])}
    },
    "custom": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke Programming"])},
      "text": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our programs look at the complex environments that inform the culture of a place by incorporating visits within and beyond the traditional art world and its traditional actors. We organize meaningful encounters with thought-leaders, writers, historians, environmentalists, designers, artists, collectors, and more."])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is at the intersections between various fields where the profound story of a place can be told. We gather in private homes, in sacred spaces, around storied tables, and behind doors most often closed to the public to connect, challenge, and learn."])}
      }
    },
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illuminating storylines linking artistic movements, cross-cultural histories and contemporary art"])}
  },
  "wine": {
    "top": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we tell stories of place through deep dives into viticultural landscapes"])},
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll to explore"])}
    },
    "enhance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRAFTED WINE EXPERIENCES"])},
      "text": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes Côtes specializes in the amalgamation of hospitality, wine production, wine consulting, events, and experiential travel."])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working with wine membership clubs, professionals, sommeliers, as well as wine collectors and enthusiasts, we curate experiences that are highly personalized and truly unique. Scouring France, both on and off the beaten path, we pride ourselves on unearthing hidden gems, and introducing our guests to the people and places with whom we've developed deep connections. Through our relationships with chefs, artisans, geologists, artists, and, of course, winemakers, we provide immersion in the French 'art de vivre' while exploring the world’s most revered wine regions. Together with these exceptional individuals, we craft encounters and meetings-of-the-minds across extraordinary landscapes to convey the stories of these places. Bespoke itineraries include destinations such as Burgundy, Jura, the Loire Valley, Bordeaux, Savoie, Provence, Champagne, and Piedmont."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our wine education programs based in Burgundy provide a fun, informative and original experience for anyone curious about wine. We tailor our classes to meet your requirements. From viticulture to viniculture, wine assessment and tasting, we divide our time between the “classroom,” the winery and the vineyard. Whether you are a seasoned professional or a new enthusiast in the world of wine, our mission is to provide a unique experience that will allow you to enhance your knowledge of wine and Burgundy."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We take pride in discovering the wines of the new generation. Our partners are the trailblazers and stars of tomorrow who share a deep concern for the issues caused by the climate crisis and unsustainable farming techniques. Adapting their practices in honorable ways, they are committed to producing the highest quality product, while remaining deeply connected to terroir and committed to their work. Most importantly, they are fantastic winemakers and human beings."])}
      },
      "subtitle": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine trips"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine education"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine consulting"])}
      }
    },
    "sense": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense\nof place"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create unforgettable experiences and discoveries fostering deep connections surrounding wine and terroir."])}
    },
    "custom": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our\nValues"])},
      "text": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe that beautiful terroirs are worth fighting for. By changing the way we see and experience them, we can have a positive impact on future generations. We strive to make our experiences sustainable by bridging the gap between generations, promoting green practices, and working with talented and inspiring people who are dedicated to ‘building back better’. Built into each project is a donation towards a project of sustainability or ecological conservation within the wine region we are visiting."])}
      }
    }
  },
  "experiences": {
    "inspired": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspried\nMoments"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art, Wine, Exchange"])},
      "text": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Hautes Côtes, we are dedicated to creating inspired and authentic experiences for curious minds and palates. We foster cross-cultural and cross-disciplinary exchange through the blending of art and wine. Our focus caters to connoisseurs and aficionados who have a deep appreciation for the creative processes surrounding these two worlds."])}
      }
    },
    "exhibition": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terra\nExhibition"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contemporary art in the heart of Burgundy"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes Côtes proudly presents TERRA—a site-specific group exhibition featuring painting, sculpture, and mixed media work by international artists across historic sites in Burgundy. Co-curated by Jenn Ellis of Apsara Studio and art historian Emie Diamond, and produced by Milena Berman, TERRA engages historic Burgundian spaces, putting them in conversation with inspiring works of art from around the world. A 5-week period of events, visits, and thoughtful encounters, TERRA creates community through its programming in unique spaces from a winery chateau, to an ancient theatre, and in a 15th century convent."])}
    },
    "wine_hiphop": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine &\nHip Hop"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncorking an unexpected wine pairing"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together with Jermaine Stone, the trailblazing tastemaker and host of the Wine & Hip Hop show and Jeremy Seysses, winemaker and proprietor of Domaine Dujac, HC produces a groundbreaking wine tasting paired with hip hop in the most emblematic property of Burgundy: the 11th century Clos de Vougeot and its Chateau. The first rendition in this space featured newly-knighted Chevalier de Tastevin Mike D of the Beastie Boys, DJ Gilles Peterson, and DJ Show, with special guest MC Solaar. Participating wineries included Domaine Arnoux Lachaux, Chanterêves, Domaine Roulot, Domaine des Comtes Lafon, Dandelion, Domaine Drouhin, and more."])}
    },
    "roadside": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadside\nTerroir"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deep-dive programs looking beneath and above ground"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HC has teamed up with geologist Brenna Quigley to offer deep-dive terroir-oriented trips for wine professionals and wine lovers alike. Our first trip explored Burgundy and the Jura through a week of expert-led tastings, vineyard hikes, and visits to some of the most exciting domaines - both classic and new wave - of the moment. These enriching trips are elevated by unique shared moments with winemakers around the table, over a picnic in the vines, even at a village festival around a bonfire."])}
    },
    "dinners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine\nAnd Art\nDinners"])},
      "subtitle": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“The Art + Wine dinner at Art Basel was a soulful, wonderful evening”"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Museum Curator -"])}
      },
      "text": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevated wine tastings and dinners in the context of art happenings such as fairs, exhibitions, and in gallery spaces. HC brings together winemakers and artists, fostering connections across the disciplines that engage patrons and collectors through shared, memorable moments."])}
      }
    },
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illuminating storylines linking artistic movements, cross-cultural histories and contemporary art throught travel."])}
  },
  "newsletter": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay updated"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "pagetitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our\nNewsletter"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay in the loop with the latest updates, exclusive offers, and inspiring content. Sign up to unlock a world of exciting news on wine, art and everything in between – delivered on an irregular basis to your inbox."])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plan your experience"])},
    "form": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you are a professional seeking to curate a memorable moment for a select group of clients or colleagues, or a group interested in an educational deep-dive, we will be delighted to custom-design your experience. We specialize in art programming, wine consultation, and education, offering comprehensive services including event production, trip planning, coordination, execution, and concierge services along the way."])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info[at]hautescotes.com"])},
      "interess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am interested in..."])},
      "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
      "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine"])},
      "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We respect your privacy"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your message, we will contact you soon!"])}
    }
  },
  "we_support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Support"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In an effort to support the conservation of terroir and to promote projects aiming to “build back better”, each Hautes Côtes trip or event includes a donation to a regional preservation fund, a regenerative farming project, or a wine- or culture- industry impact foundation supporting climate or social justice. Some trips are designed to directly engage with and support a specific foundation, association, or sustainability project visited as part of the experience."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of the projects we support:"])}
  },
  "journey": {
    "link": {
      "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download instruction"])},
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit site"])}
    }
  },
  "whoweare": {
    "title": {
      "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
      "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who\nWe Are"])}
    },
    "subtitle": {
      "milena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milena Berman"])},
      "loic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loïc Lamy"])}
    },
    "text": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes Côtes was started by Milena Berman and Loïc Lamy in 2021. Together with their Beaune-based team of wine and art specialists, they produce experiences blending their two worlds across France and Europe, working collaboratively with an extensive network of experts, makers, and creatives. Hautes Côtes plans programs for private groups, non-profit foundations, members’ clubs, auction houses, museum patrons, and wine and art world professionals."])},
      "milena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is originally from California and has been living in Burgundy since 2013. She studied art history as an undergraduate and received her Master’s in art business with Sotheby’s Institute of Art. Before co-founding Hautes Côtes, Milena worked in commercial and non-profit galleries, for documenta13 in Germany, for a private collection, and for wineries in France and the US. Since 2013, she has created and hosted international travel programs for patrons, curators, and directors of the world’s top cultural institutions. Being based in Beaune, Milena has been immersed in wine and gastronomy, and found a home in its dynamic wine community. Today she produces cultural moments in the realms of wine and art and is most interested in their intersections, fostering connections between people and places through meaningful experiences."])},
      "loic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was born in Paris and raised in the French Alps. After studying business and working abroad in Singapore, Hong Kong, Australia and the US, he came back to his native France to fulfill his passion for wine. Immersion in these different cultures and their gastronomic traditions led him back to the exploration of his own as he became deeply interested in the concepts of taste, terroir and sense of place. Loïc began working with a negociant in Chablis, then studied sommellerie at Suze-la-Rousse. After a few stints in wine bars in the Alps, his passion for pinot noir took him to Burgundy where he has worked in all aspects of the wine industry, from the vines to the cellar to the consumer, working for one of the most prestigious estates in the Côte de Beaune. Today, he stays in touch with production as he runs an organic and biodynamic micro négoce from his home in the Hautes Côtes de Beaune, Vins Saisons."])}
    }
  },
  "planexperience": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plan your experience"])},
    "text": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you are a professional seeking to curate a memorable moment for a select group of clients or colleagues, or a group interested in an educational deep-dive, we will be delighted to custom-design your experience. We specialize in art programming, wine consultation, and education, offering comprehensive services including event production, trip planning, coordination, execution, and concierge services along the way."])}
    },
    "link": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])}
    }
  },
  "404": {
    "title": {
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doesn't exist..."])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back home"])}
  }
}