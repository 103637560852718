import { createRouter, createWebHistory } from "vue-router";
import i18n from "../i18n";
import Home from "../views/Home.vue";

const locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
const localeLabel = ":locale(" + locales.join("|") + ")";
const getLocale = () => {
  // const userLang = (navigator.language || navigator.userLanguage)
  //   .toLowerCase()
  //   .substring(0, 2);

  // const id = locales.indexOf(userLang);
  // return id > -1 ? locales[id] : locales[0];
  return "en";
};

const routes = [
  {
    path: "/",
    redirect: "/" + getLocale(),
  },
  {
    path: `/${localeLabel}`,
    name: "Home",
    component: Home,
  },
  {
    path: `/${localeLabel}/contact`,
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "presentation" */ "../views/Contact.vue"),
  },
  {
    path: `/${localeLabel}/art`,
    name: "Art",
    component: () =>
      import(/* webpackChunkName: "presentation" */ "../views/Art.vue"),
  },
  {
    path: `/${localeLabel}/wine`,
    name: "Wine",
    component: () =>
      import(/* webpackChunkName: "presentation" */ "../views/Wine.vue"),
  },
  {
    path: `/${localeLabel}/experiences`,
    name: "Experiences",
    component: () =>
      import(/* webpackChunkName: "presentation" */ "../views/Experiences.vue"),
  },
  {
    path: `/${localeLabel}/newsletter`,
    name: "Newsletter",
    component: () =>
      import(/* webpackChunkName: "newsletter" */ "../views/Newsletter.vue"),
  },
  {
    path: `/${localeLabel}/legals`,
    name: "Legals",
    component: () =>
      import(/* webpackChunkName: "legals" */ "../views/Legals.vue"),
  },
  {
    path: `/${localeLabel}/404`,
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    path: `/trip/:uid`,
    name: "Journey",
    component: () =>
      import(/* webpackChunkName: "journey" */ "../views/Journey.vue"),
  },
  {
    path: `/${localeLabel}/we-support`,
    name: "We Support",
    component: () =>
      import(/* webpackChunkName: "presentation" */ "../views/WeSupport.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/" + getLocale() + "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, 100);
    });
  },
});

router.beforeEach((to, from, next) => {
  let language = to.params.locale;
  if (!language) {
    language = getLocale();
  }
  i18n.global.locale.value = language;
  next();
});

router.afterEach((to, from) => {
  if (to.name === "Home" && from.name === undefined) {
    to.meta.intro = true;
  } else {
    to.meta.intro = false;
  }
  to.meta.isTransition = to.params.trans;
});

export default router;
