<template>
  <ProgressLoader />
  <Navbar v-if="$route.name != 'Intro' && $route.name != 'Journey'" />
  <router-view id="view" v-slot="{ Component, route }">
    <transition :name="route.meta.isTransition && 'fadeh'" mode="out-in">
      <component :is="Component" :intro="route.meta.intro" />
    </transition>
  </router-view>
</template>

<script>
import Navbar from "@/components/common/Navbar";
import ProgressLoader from "@/components/ProgressLoader";
import { LOAD_ASSETS } from "@/store/types";
import { onMounted } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    Navbar,
    ProgressLoader,
  },
  setup() {
    onMounted(() => {
      const store = useStore();
      const appHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      };

      window.addEventListener("resize", appHeight);
      appHeight();

      store.dispatch(LOAD_ASSETS);
    });
  },
};
</script>

<style lang="scss">
@import "@/styles/reset.sass";
@import "@/styles/mixins.scss";
@import "@/styles/fonts.scss";
@import "@/styles/layout.scss";

#view img:not(.introImg) {
  @include respond-to("md-down") {
    width: 50%;
  }
}
</style>
