<template>
  <div
    ref="container"
    class="contain"
    :class="[{ home: page == 'home' }, { hide: hover == 'wine' }]"
  >
    <div class="imgContainer">
      <div class="img"></div>
    </div>
    <router-link
      :to="{
        name: 'Art',
        params: { locale: `${$route.params.locale}`, trans: true },
      }"
      class="art"
      :class="[{ home: page == 'home' }, { hide: hover == 'wine' }]"
    >
      <h2 class="xl-title" v-if="page == 'home'">
        <span class="left">{{ t("home.art.title.first") }}</span>
        <span class="right">{{ t("home.art.title.second") }}</span>
        <span class="center">{{ t("home.art.title.third") }}</span>
      </h2>
      <div class="description" v-if="page == 'home'">
        <h4>{{ t("home.art.description") }}</h4>
        <span>{{ t("home.explore") }}</span>
      </div>
      <div v-if="page != 'home'">
        <span class="text discover">{{ t("menu.discover") }}</span>
        <h2 class="xl-title">{{ t("menu.art") }}</h2>
      </div>
    </router-link>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";

export default {
  name: "ArtChoice",
  props: {
    page: {
      type: String,
      default: "",
    },
    hover: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { t } = useI18n();
    const container = ref(null);
    // const width = ref(50);

    // watch(props, () => {
    //   const oldWidth = width.value;
    //   if (props.hover == "wine") {
    //     console.log("wine");
    //     width.value = 41;
    //   } else if (props.hover == "art") {
    //     console.log("art");
    //     width.value = 60;
    //   } else {
    //     console.log("other");
    //     width.value = 50;
    //   }
    //   if (oldWidth != width.value && props.page == "home") {
    //     animate();
    //   }
    // });

    // const animate = () => {
    //   gsap.to(container.value.querySelector('.art'), {
    //     delay: 0,
    //     duration: 1.3,
    //     width: width.value + "%",
    //     ease: "cubic-bezier(.285,.02,.18,1.02)",
    //   });
    // }

    return { t, container };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.contain {
  background-image: url("/assets/img/home_art_abstract2.webp");
  background-size: cover;
  background-position: center;
  width: 100%;
  overflow: hidden;
  transition: background-size 1.3s cubic-bezier(0.285, 0.02, 0.18, 1.02);
  &:not(.home) {
    position: relative;
    & .imgContainer {
      bottom: 0;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      will-change: transform;
      & .img {
        width: 100%;
        height: 100%;
        background-image: url("/assets/img/component_artchoice.webp");
        background-size: cover;
        transition: transform 2s cubic-bezier(0.285, 0.02, 0.18, 1.02);
        background-position: center 0%;
      }
    }
    &:hover {
      & .imgContainer {
        & .img {
          transform: scale(1.03);
        }
      }
    }
  }
  &.home {
    width: 100%;
    & .imgContainer {
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 62%;
      right: -11%;
      height: 100%;
      transition: transform 1.3s cubic-bezier(0.285, 0.02, 0.18, 1.02);
      will-change: transform;
      @include respond-to("md-down") {
        display: none;
      }
      & .img {
        width: 100%;
        height: 100%;
        background-image: url("/assets/img/home_art_abstract2.webp");
        background-size: cover;
        transition: transform 1.3s cubic-bezier(0.285, 0.02, 0.18, 1.02);
        // background-position: center 0%;
      }
    }
    &:hover .imgContainer {
      transform: translateX(calc(-10 / 60 * 100%));
      @include respond-to("md-down") {
        transform: none;
        & .img {
          transform: scale(1.3);
        }
      }
    }
    &:hover a {
      transform: translateX(calc(-10 / 60 * 100% / 2));
      @include respond-to("md-down") {
        transform: none;
      }
    }
    &.hide {
      right: -20%;
      & .imgContainer {
        transform: translateX(calc(10 / 60 * 100%));
        @include respond-to("md-down") {
          transform: none;
        }
      }
      & a {
        transform: translateX(calc(10 / 60 * 100% / 1.5));
        @include respond-to("md-down") {
          transform: none;
        }
      }
    }
  }
}
.art {
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1.3s cubic-bezier(0.285, 0.02, 0.18, 1.02);
  will-change: transform;
  height: 66vh;
  width: 100%;
  overflow: hidden;
  @include respond-to("md-down") {
    position: inherit;
    min-height: 50vh;
    max-height: calc(var(--vh, 1vh) * 50);
  }
  &.home {
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    @include respond-to("md-down") {
      position: inherit;
      width: 100%;
      min-height: 50vh;
      max-height: calc(var(--vh, 1vh) * 50);
    }
    // &.hide {
    //   width: 30%;
    // }
    &:hover {
      // width: 70%;
      & .description span {
        @include respond-to("md-down") {
          display: none;
        }
        bottom: 10%;
      }
      & .description h4 {
        @include respond-to("md-down") {
          opacity: 1;
        }
        opacity: 0;
      }
    }
  }
  &:hover h2 span {
    &.right {
      transform: translateX(20%);
    }
    &.left {
      transform: translateX(-20%);
    }
  }
  @include respond-to("md-down") {
    h2 span {
      &.right {
        transform: translateX(20%);
      }
      &.left {
        transform: translateX(-20%);
      }
    }
  }
}

h2 {
  transition: opacity 1.3s cubic-bezier(0.285, 0.02, 0.18, 1.02);
  color: #ffffff;
  & span {
    display: block;
    transition: transform 1.3s cubic-bezier(0.285, 0.02, 0.18, 1.02);
    will-change: transform;
    &.right {
      text-align: right;
    }
    &.center {
      text-align: center;
    }
  }
}

.text {
  display: block;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-align: center;
}

.description {
  transition: opacity 1.3s cubic-bezier(0.285, 0.02, 0.18, 1.02);
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 10%;
  text-transform: uppercase;

  & h4 {
    font-size: 14.625px;
    letter-spacing: 0.1em;
    opacity: 1;
    transition: opacity 0.7s cubic-bezier(0.285, 0.02, 0.18, 1.02);
  }

  & span {
    font-size: 14px;
    text-transform: none;
    display: block;
    text-align: center;
    position: absolute;
    bottom: -15vh;
    transition: bottom 1.3s cubic-bezier(0.285, 0.02, 0.18, 1.02),
      background-color 0.21s ease-out;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px 40px;
    margin: 0;
    color: #fff;
    letter-spacing: 0.1em;
    cursor: pointer;
    transform: translateY(50%);
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
.discover {
  font-size: 14px;
  letter-spacing: 0.1em;
}
.hide h2,
.hide .description {
  opacity: 0.3;
}
</style>
