<template>
  <div>
    <div class="main" v-if="intro">
      <Intro />
    </div>
    <div class="home">
      <ArtChoice
        page="home"
        :hover="component"
        @mouseenter="handleHover('art')"
        @mouseleave="handleHover('')"
      />
      <WineChoice
        page="home"
        :hover="component"
        @mouseenter="handleHover('wine')"
        @mouseleave="handleHover('')"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Intro from "@/components/Intro.vue";
import ArtChoice from "@/components/ArtChoice";
import WineChoice from "@/components/WineChoice";

export default {
  name: "Home",
  components: {
    Intro,
    ArtChoice,
    WineChoice,
  },
  props: {
    intro: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const component = ref("");
    const handleHover = (el) => {
      component.value = el;
    };

    return {
      component,
      handleHover,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.main {
  width: 100%;
}
.home {
  display: flex;
  @include respond-to("md-down") {
    flex-direction: column;
  }
}
</style>
